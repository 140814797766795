import React, { useState, useRef, useEffect } from "react";
import Header from './header';
import Footer from './footer';
import projtimg from '../assets/img/project-img.jpg';
import workshopprojtimg from '../assets/img/workshop_img.jpg';
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Media, Video } from '@vidstack/player-react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import ScrollAnimation from 'react-animate-on-scroll';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {  InputAdornment } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
    Link,
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    useNavigate
} from "react-router-dom";
// modal code start here
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

// modal code start here

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { isComingSoon, generateUniqueToken } from "../utils/helper";
import {
  addConnect,
  otpUpdate
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
function addZero(i) {
  if (i < 10) {i = "0" + i}
  return i;
}
// modal code end here
const Project = () => {
    const { projectData } = useSelector((state) => state.pageContents);
    let navigate = useNavigate();
const [pageselect, setPageselect] = React.useState('');
    const [videof, setVideof] = React.useState('');
    const [imgfallvid, setImgfallvid] = React.useState('');
    const [openvideo, setOpenVideo] = React.useState(false);

    const handleClickOpenVideo = (video, image) => {
        setImgfallvid(image);
        setVideof(video);

        setOpenVideo(true);
    };
    const handleClosevideo = () => {
        setOpenVideo(false);
    };
    useEffect(() => {

    }, []);

    const [formdateto, setFormdateto] = useState(null);
    const recaptchaRef = React.useRef();
    const datePickerRef = useRef(null);
    useEffect(() => {
        console.log('hi');;
    
        var date = new Date();
        setFormdateto(date);
    
      }, []);
      const openDatePicker = () => {
        alert('hi');  
        if (datePickerRef.current) {
          datePickerRef.current.focus();
        }
      };
    const [opencase, setOpenCase] = React.useState(false);

    const handleClickOpenCasestudies = () => {
      setOpenCase(true);
    };
    const handleClosecase = () => {
      setOpenCase(false);
    };
  

  const [opencaseyes, setOpenCaseyes] = React.useState(false);


  const handleClosecaseyes = () => {
    setOpenCaseyes(false);
  };



  const [opencaseno, setOpenCaseno] = React.useState(false);

  const handleClickOpenCaseno = () => {
    setOpenCaseno(true);
    setOpenCase(false);
  };
  const handleClosecaseno = () => {
    setOpenCaseyes(false);
    setOpenCaseno(false);
  };




  const formFields = {
    name: '',
    email: '',
    phone: '',
    contact_us: '',
    casestudyname: '',
    pagename: 'morecasestudies',

    sh_date: '',
    sh_time: '01:30 PM',

    sh_date_new: null,
    sh_time_new: null

  }
  const [opendate, setOpenDate] = useState(false); // State to control the open/close
  const handleIconClick = () => {
     setOpenDate(true); // Open the picker when the icon is clicked
   };
 
  const handleDate = (e) => {
    let temp = { ...formValues }

    var d = new Date(e);
    var date;
    var month;
    if (d.getDate() < 10) {
      date = "0" + d.getDate();
    }
    else {
      date = d.getDate();
    }

    if ((d.getMonth() + 1) < 10) {
      month = "0" + (d.getMonth() + 1);
    }
    else {
      month = (d.getMonth() + 1);
    }
    var datestring = d.getFullYear() + "-" + month + "-" + date;
    temp.sh_date = datestring;
    temp.sh_date_new = e;

    console.log(datestring);
    setFormErrors({ ...formErrors, ['sh_date']: null });
    setFormValues(temp);
    setOpenDate(false);
  }



  const handleTime = (e) => {
    let temp = { ...formValues }
    console.log(e);
    var d = new Date(e);
    var hour;
    var hours;
    var mintues;
    hours=d.getHours();
    hour = d.getHours();
    mintues = d.getMinutes();
    console.log(hour);
   
    hour = hour % 12;
    hour = hour ? hour : 12;
    temp.sh_time = addZero(hour) + ':' + addZero(mintues) + ' ' + (hours >= 12 ? 'PM' : 'AM');
    temp.sh_time_new = e;

    console.log(temp.sh_time);
    setFormErrors({ ...formErrors, ['sh_time']: null });
    setFormValues(temp);
  }
  const [loader, setLoader] = React.useState(true);

  const { ip_address } = useSelector((state) => state.userdata);
  const [formValues, setFormValues] = React.useState(formFields);
  const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [otpid, setotpid] = React.useState(null);

  const [openotp, setOpenotp] = React.useState(false);

  const handleClickOpenotp = () => {
    setOpenotp(true);
  };
  const handleCloseotp = () => {
    setOpenotp(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(formValues);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  }
  const handleClickOpenCaseyes = (title) => {
    var casedeat = { ...formValues };
    casedeat.casestudyname = title;
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.pagename = 'bookdemoproject';
    casedeat.sh_date = '2024-02-08';
    casedeat.sh_time = '1:30 PM';
    casedeat.sh_date_new = null;
    casedeat.sh_time_new = null;
    setFormValues(casedeat);
    //    recaptchaRef.current.reset();
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOtpin({
      otpIn_first: "",
      otpIn_secon: "",
      otpIn_thir: "",
      otpIn_forth: "",
      otpIn_fifth: "",
      otpIn_six: "",
      error_first: 1,
      error_secon: 1,
      error_thir: 1,
      error_forth: 1,
      error_fifth: 1,
      error_six: 1,
      signIn_err: '0',
      signIn_mesage: '',
      status: 'fail',
      id: ''
    });
    setOpenCaseyes(true);

  };
  const handleClickOpenGetintouch = (title) => {


    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);


    var casedeat = { ...formValues };
    casedeat.casestudyname = title;
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.pagename = 'bookdemoproject';
    casedeat.sh_date = '';
    casedeat.sh_time = '01:30 PM';
    casedeat.sh_date_new = null;
    casedeat.sh_time_new = null;
    setFormValues(casedeat);
    setOtpin({
      otpIn_first: "",
      otpIn_secon: "",
      otpIn_thir: "",
      otpIn_forth: "",
      otpIn_fifth: "",
      otpIn_six: "",
      error_first: 1,
      error_secon: 1,
      error_thir: 1,
      error_forth: 1,
      error_fifth: 1,
      error_six: 1,
      signIn_err: '0',
      signIn_mesage: '',
      status: 'fail',
      id: ''
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(false);

    console.log(validateForm());
    if (validateForm()) {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      let formData = { ...formValues };
      formData.recaptcha = recaptchaRef.current.getValue();
      setPageselect(formData.pagename);
      addConnect(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.data.status) {
            setotpid(response.data.lastid);
            //setSuccessMessage(response.data.message);
            setFormValues(formFields);
            recaptchaRef.current.reset();
            setOpenCaseyes(false);
            setOpenotp(true);
            // setTimeout(() => {
            //  // setOpen(false);
            // }, 2000);


          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage('Something went wrong. Please try again later.');
        });
    }
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    //setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const validateForm = () => {
    let isValid = true;
    let errors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && key !== 'recaptcha' && key !== 'phone' && key !== 'sh_date_new' && key!='sh_time_new' && key !== 'contact_us' && key !== 'casestudyname'


      ) {
        isValid = false;
        errors[key] = 'This field is required';
      } else if (key === 'email' && !isEmail(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      }
      // else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
      //   isValid = false;
      //   errors[key] = 'Invalid phone number';
      // }

    });
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      isValid = false;
      errors.recaptcha = 'Please verify captcha';
    }
    console.log(errors);
    setFormErrors(errors);
    return isValid;
  }

  const [otpIn, setOtpin] = useState({
    otpIn_first: "",
    otpIn_secon: "",
    otpIn_thir: "",
    otpIn_forth: "",
    otpIn_fifth: "",
    otpIn_six: "",
    error_first: 1,
    error_secon: 1,
    error_thir: 1,
    error_forth: 1,
    error_fifth: 1,
    error_six: 1,
    signIn_err: '0',
    signIn_mesage: '',
    status: 'fail',
    id: ''
  });
  const inputEventOtpIn = (event) => {
    console.log(event);
    setOtpin((preValue) => {

      if (event.target.name === 'otpIn_first') {

        return {
          otpIn_first: event.target.value,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: 0,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_secon') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: event.target.value,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: 0,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_thir') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: event.target.value,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: 0,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_forth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: event.target.value,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: 0,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }



      if (event.target.name === 'otpIn_fifth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: event.target.value,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: 0,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_six') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: event.target.value,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: 0,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };
      }
    });
    if (event.target.name === 'otpIn_first') {

      document.getElementById('otpIn_secon').focus();
    }

    if (event.target.name === 'otpIn_secon') {

      document.getElementById('otpIn_thir').focus();
    }
    if (event.target.name === 'otpIn_thir') {


      document.getElementById('otpIn_forth').focus();
    }

    if (event.target.name === 'otpIn_forth') {


      //   document.getElementById('otpIn_fifth').focus();
    }



    //      if(event.target.name==='otpIn_fifth'){


    //           document.getElementById('otpIn_six').focus();
    //  }
    console.log(otpIn);

  }

  React.useEffect(() => {
    if (otpIn.otpIn_first != '' && otpIn.otpIn_secon != ''
      && otpIn.otpIn_thir != '' && otpIn.otpIn_forth != '') {
        handleSubmitotp();
    }
 
 }, [otpIn]);
  const handleSubmitotp = (event) => {

    setErrorMessage('');
    setSuccessMessage('');
    if (otpIn.otpIn_first == '' || otpIn.otpIn_secon == ''
      || otpIn.otpIn_thir == '' || otpIn.otpIn_forth == '') {
      setErrorMessage('Please Enter Valid otp');
      return false;
    }
    var otp = otpIn.otpIn_first + otpIn.otpIn_secon + otpIn.otpIn_thir + otpIn.otpIn_forth + otpIn.otpIn_fifth + otpIn.otpIn_six;

    setSubmitting(true);
    otpUpdate({ id: otpid, otp: otp })
      .then(res => {
        console.log(res.data);

        if (res.data.status == true) {
          // setOpen(true);
          setOpenotp(false);
          setSubmitting(false);
          // setTimeout(() => {
          //   setOpen(false);
          // }, 3000);


          if(pageselect=='bookdemoproject'){
            navigate('/thankyou/bookademoproject');
          }



        }
        else {

          setErrorMessage(res.data.message);
          setOtpin((preValue) => {

            return {
              otpIn_first: '',
              otpIn_secon: '',
              otpIn_thir: '',
              otpIn_forth: '',
              otpIn_fifth: '',
              otpIn_six: '',
              error_first: 0,
              error_secon: 0,
              error_thir: 0,
              error_forth: 0,
              error_fifth: 0,
              error_six: 0,

            };
          })
          setSubmitting(false);
        }
      });
  }

    var newprojectlist = [];
    var limitloc = 3;
    var temploc = [];
    if (projectData?.projects.length) {

    }
    for (let i = 0; i < projectData.projects.length; i++) {
        if (i < limitloc) {
            temploc.push(projectData.projects[i]);
        }
        else {
            newprojectlist.push(temploc);
            temploc = [];
            temploc.push(projectData.projects[i]);

            limitloc = limitloc + newprojectlist[0].length;
        }
        if (i == (projectData.projects.length - 1)) {
            newprojectlist.push(temploc);
        }
    }

    return (
        <>
            <Header />
            {
                projectData?.projectbanner &&
                <div className='main-sec-homes about-main'>
                    <div class="main-sec-home about-sec-banner"  title="Our Projects Banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${projectData.projectbanner.banner_image}')` }}>

                    </div>
                    <div className='container'>
                        <div className='banner-content abut-banner-cont'>
                            <h1>{projectData.projectbanner.banner_title}</h1>
                            <p className='head-para'>{projectData.projectbanner.banner_title_short}</p>

                        </div>
                    </div>
                </div>
            }

            {
                projectData?.projectbanner &&
                <div className='main-sec-homes about-main mob-sec-new'>
                    <div class="main-sec-home about-sec-banner"   title="Our Projects Banner"  style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${projectData.projectbanner.banner_mobile_image}')` }}>

                    </div>
                    <div className='container'>
                        <div className='banner-content abut-banner-cont'>
                            <h1>{projectData.projectbanner.banner_title}</h1>
                            <p className='head-para'>{projectData.projectbanner.banner_title_short}</p>

                        </div>
                    </div>
                </div>
            }
            <div className='project-sec bg-sec-new'>
                <div className='container'>
                    {
                        projectData?.projectpage &&
                        <div className='row'>

                            <div className='proj-sec-para'>

                                <p>{projectData.projectpage.page_title}</p>

                            </div>

                        </div>
                    }

                    <div className='multi-proj-sec'>
                        <div className='container'>




                            {
                                projectData?.projects.length &&

                                <>

                                    <div className={`row`}>
                                        <>
                                            {projectData.projects.map((pro, index) => (

                                                <div className='col-md-4 project-width'>

                                                    <div class="item-project animated wow fadeIn">
                                                        <img src={process.env.REACT_APP_IMAGE_URL + pro.image}
                                                          alt={`${pro.id==1 ? 'Eform System for k12 Schools in New Jersey'  :''} ${pro.id==2 ? 'Workshop Management system for k12 Scools in New Jersey'  :''}  ${pro.id==4 ? 'Facility Rental Management System for k12 Schools in New Jersey'  :''} ${pro.id==5 ? 'Student Transcript Application for k12 Scools in New Jersey'  :''} ${pro.id==3 ? 'Inventory Management System for k12 Schools in New Jersey'  :''}  ${pro.id==6 ? 'OPRA Software'  :''} ${pro.id==7 ? 'Absence Reporting Portal for staffs k12 Schools in New Jersey'  :''} ${pro.id==8 ? 'Household Survey application for k12 Schools in New Jersey'  :''} ${pro.id==9 ? 'Athletic Consent Platform for students in K12 Schools in New Jersey'  :''} `} 
                                                        />
                                                        <div class="overlay-main">
                                                            <div class="overlay-text">{pro.title}</div>
                                                        </div>
                                                        <div class="project-overlay top-overlay view-proj">
                                                            <div class="text-projt">
                                                                <p>{pro.short_de_pro}
                                                                </p>
                                                                {(pro.project_link != '' && pro.project_link != null) &&
                                                                    <p>
                                                                        <a  target='_blank' className='view-proj-sec'  onClick={() => handleClickOpenGetintouch(pro.title)}><span>Book a Free Demo <i class="fa-solid fa-arrow-right-long"></i></span></a></p>
                                                                }
                                                                <div className='project-acn-sec main-projt-watch'>
                                                                    {(pro.video != '' && pro.video != null) &&
                                                                        <div className='proj-actn-left projet-over-left'>
                                                                            <a onClick={() => handleClickOpenVideo(pro.video, pro.video_image)}><span>Watch Video</span><i class="fa-solid fa-arrow-right-long"></i></a>
                                                                        </div>
                                                                    }
                                                                    {(pro.pro_case != '' && pro.pro_case != null && pro.pro_case == 1 ) &&
                                                                        <div className='proj-actn-right projet-over-right'>
                                                                            <Link to={`/casestudies/${pro.slug}`} ><span>View Case Studies</span><i class="fa-solid fa-arrow-right-long"></i></Link>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                            ))}
                                        </>
                                    </div>


                                </>



                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {/* modal code start here */}
            <BootstrapDialog
                onClose={handleClosevideo}
                aria-labelledby="customized-dialog-title"
                open={openvideo} className="modal-casestudy modal-watch"
            >

                <div className="btn-mdl-sec">
                    <IconButton
                        aria-label="close"
                        onClick={handleClosevideo}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >

                        < CancelIcon />

                    </IconButton>
                </div>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {(videof != null && imgfallvid != '') &&
                            <Media style={{ width: "100%" }}>
                                <Video autoplay="true" loading="visible" controls preload="true" poster={process.env.REACT_APP_IMAGE_URL + imgfallvid}>
                                    <video loading="visible" poster={process.env.REACT_APP_IMAGE_URL + imgfallvid} autoplay="true" src={process.env.REACT_APP_IMAGE_URL + videof} preload="none" data-video="0" controls />
                                </Video>
                            </Media>

                        }
                    </Typography>

                </DialogContent>

            </BootstrapDialog>

            
      <BootstrapDialog
        onClose={handleClosecaseyes}
        aria-labelledby="customized-dialog-title"
        open={opencaseyes} className={`modal-casestudy modal-yes happy-modal modal-cases final-modal date-modal ${formValues.pagename == 'morecasestudies' && 'final-no-modal'}`}
      >

        <div className="btn-mdl-sec">
          <IconButton
            aria-label="close"
            onClick={handleClosecaseyes}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >

            < CancelIcon />

          </IconButton>
        </div>
        <DialogContent dividers>
          <Typography gutterBottom>
            <h2>Happy to help!</h2>
            {formValues.pagename == 'bookdemoproject' &&
            <>
              <p className="new-demo-head">You are requesting a demo for </p>
              <p className="new-demo-para">"{formValues.casestudyname}"</p>
            </>
          
            }
            
            {/* <p>Let us send you more case studies</p> */}

            <div className="form-sec-main">
              <div className='form-sec form-modal form-modals'>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >

                  <TextField id="standard-basic" label="Your Full Name" variant="standard"
                    name="name"
                    className={classNames("form-control", { 'is-invalid': formErrors.name })}
                    onChange={handleInputChange}
                    style={{ backgroundImage: 'none' }}
                    value={formValues.name}

                  />
                  {
                    formErrors.name &&
                    <div className="invalid-feedback">
                      <div className='error-msg'>
                        {formErrors.name}
                      </div>
                    </div>
                  }
                </Box>
              </div>
              <div className='form-sec form-modal form-modals'>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >

                  <TextField id="standard-basic" label="Your Email Id" variant="standard"
                    name="email"
                    className={classNames("form-control", { 'is-invalid': formErrors.email })}
                    onChange={handleInputChange}
                    style={{ backgroundImage: 'none' }}
                    value={formValues.email}

                  />
                  {
                    formErrors.email &&
                    <div className="invalid-feedback">
                      <div className='error-msg'>
                        {formErrors.email}
                      </div>
                    </div>
                  }
                </Box>
                <p className='veri-secss'><span>This email will be verified</span></p>

              </div>
              <div className='form-sec form-modal form-modals'>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >

                  <TextField id="standard-basic" label="Your Contact Number" variant="standard"

                    name="phone"
                    className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                    onChange={handleInputChange}
                    style={{ backgroundImage: 'none' }}
                    value={formValues.phone}
                  />
                  {
                    formErrors.phone &&
                    <div className="invalid-feedback">
                      <div className='error-msg'>
                        {formErrors.phone}
                      </div>
                    </div>
                  }
                </Box>

                <div class="tooltipnew"> <i class="fa-solid fa-info-circle"></i>
                  <span class="tooltiptext"> In case you need us to contact you</span>
                </div>

              </div>

              {formValues.pagename == 'bookdemoproject' &&
                <>
                  <div className='form-sec form-modal date-modal-case data-scroll-modal'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          'DatePicker',
                          'MobileDatePicker',
                          'DesktopDatePicker',
                          'StaticDatePicker',
                        ]}
                      >

                        <MobileDatePicker
                          label="Select Date"
                          variant="standard"
                          inputFormat="MM/dd/yyyy"
                          placeholder="MM/dd/yyyy"
                          value={formValues.sh_date_new}
                          open={opendate} // Control the open state of the picker
                          onClose={() => setOpenDate(false)} 
                          onOpen={() => setOpenDate(true)} // Close the picker when it opens via text field
                         
                          onChange={handleDate}
                          minDate={dayjs(formdateto)}
                          renderInput={(params) => <TextField {...params} 
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleIconClick}>
                                  <CalendarMonthIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                         
                          />}
                          
                        />
                          <span className="calend-icon" onClick={handleIconClick}>
                          <CalendarMonthIcon /></span>
                      

                      </DemoContainer>
                    </LocalizationProvider>

                    {
                      formErrors.sh_date &&
                      <div className="invalid-feedbackcontact">
                        <div className='error-msg'>
                          {formErrors.sh_date}
                        </div>
                      </div>
                    }

                  </div>
                  {/* <div className='form-sec form-modal date-modal-case data-modal-mob'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          'MobileTimePicker',
                          'DatePicker',
                          'MobileDatePicker',
                          'DesktopDatePicker',
                          'StaticDatePicker',
                        ]}
                      >


                        <MobileTimePicker
                          label="Select Time"
                          variant="standard"
                          inputFormat="hh:mm aa"
                          placeholder="hh:mm aa"
                          value={formValues.sh_time_new}

                          onChange={handleTime} className="time-sec"

                        />

                      </DemoContainer>
                    </LocalizationProvider>

                    {
                      formErrors.sh_time &&
                      <div className="invalid-feedbackcontact">
                        <div className='error-msg'>
                          {formErrors.sh_time}
                        </div>
                      </div>
                    }

                  </div> */}
                </>

              }


              <div className='form-sec form-modal'>

                <p className="case-privacy">Your information is secured with us, please read our <Link to='/privacy-policy' className="case-link-prv">privacy policy</Link> </p>
              </div>
              <div className='form-sec form-modal' >


                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
                />
                {
                  formErrors.recaptcha &&
                  <div className="invalid-feedback" style={{ display: 'block' }}>
                    <div className='error-msg'>
                      {formErrors.recaptcha}
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className='button-sec button-case'>

              <a className='btn btn-sec btn-yes' onClick={handleSubmit}>Submit

                {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>}
              </a>
            </div>
            <div className='validate-sec validate-sec-mode'>
              {
                successMessage &&
                <div className="success-msg-sec">
                  <p className="succ-msg">{successMessage}
                  </p>
                </div>
              }
              {
                errorMessage &&
                <div className="error-msg-sec">
                  <p className="error-msg">{errorMessage}
                  </p>
                </div>
              }
            </div>
          </Typography>

        </DialogContent>

      </BootstrapDialog>


   
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} className="modal-casestudy contact-thanku modal-thanku-connet"
      >

        <div className="btn-mdl-sec">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >

            < CancelIcon />

          </IconButton>
        </div>
        <DialogContent dividers>
          <Typography gutterBottom>
            <h2>Thank You !!</h2>
            <p>Your message is successfully delivered to the concerned team, they will do the needful soon</p>


          </Typography>

        </DialogContent>

      </BootstrapDialog>

      <BootstrapDialog
        onClose={handleCloseotp}
        aria-labelledby="customized-dialog-title"
        open={openotp} className="modal-casestudy modal-yes happy-modal otp-modal-new"
      >

        <div className="btn-mdl-sec">
          <IconButton
            aria-label="close"
            onClick={handleCloseotp}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >

            < CancelIcon />

          </IconButton>
        </div>
        <DialogContent dividers className="new-otp">
          <Typography gutterBottom >
            <p className="otp-head">Please check your spam or junk folder, if your authentication code is not visible in your inbox
            </p>

            <div className="form-sec-main otp-main-secss">

              <div className='form-sec form-modal'>
                <div className='otp-form form__group inputs d-flex flex-row justify-content-center mt-2'>
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_first" id="otpIn_first" onChange={inputEventOtpIn} value={otpIn.otpIn_first} />
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_secon" id="otpIn_secon" onChange={inputEventOtpIn} value={otpIn.otpIn_secon} />
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_thir" id="otpIn_thir" onChange={inputEventOtpIn} value={otpIn.otpIn_thir} />
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_forth" id="otpIn_forth" onChange={inputEventOtpIn} value={otpIn.otpIn_forth} />
                </div>



              </div>
              <div className='button-sec button-case btn-otp'>

                <a className='btn btn-sec btn-yes btn-sub' onClick={handleSubmitotp}>Submit

                  {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>}
                </a>
              </div>
              <div className='validate-sec validate-sec-mode width-otp'>
                {
                  successMessage &&
                  <div className="success-msg-sec">
                    <p className="succ-msg">{successMessage}
                    </p>
                  </div>
                }
                {
                  errorMessage &&
                  <div className="error-msg-sec">
                    <p className="error-msg">{errorMessage}
                    </p>
                  </div>
                }
              </div>
            </div>
          </Typography>

        </DialogContent>

      </BootstrapDialog>
            {/* modal code end here   */}
        </>
    )
}
export default Project;

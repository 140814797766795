import React, { useState } from "react";
import Header from './header';

import projectimg from '../assets/img/bg-project-sec.png';
import visionimg from '../assets/img/vision_img.png';
import callimg from '../assets/img/talk_img.png';
import unqueimg from '../assets/img/unique.png';
import Footer from './footer';
import whiteserviceone from '../assets/img/white_service-one.png';
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Media, Video } from '@vidstack/player-react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import { isComingSoon, generateUniqueToken } from "../utils/helper";
import {
  addConnect,
  otpUpdate
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";
import ScrollAnimation from 'react-animate-on-scroll';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import  secureLocalStorage  from  "react-secure-storage";

// modal code start here
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
// modal code end here
const Home = () => {
  let navigate = useNavigate();
  const { homeData } = useSelector((state) => state.pageContents);
  console.log(homeData);
  const [videof, setVideof] = React.useState('');
  const [imgfallvid, setImgfallvid] = React.useState('');
  const [openvideo, setOpenVideo] = React.useState(false);
  const handleClickOpenVideo = (video, image) => {
    setImgfallvid(image);
    setVideof(video);

    setOpenVideo(true);
  };
  const handleClosevideo = () => {
    setOpenVideo(false);
  };
  const recaptchaRef = React.useRef();
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
    console.log('generateUniqueToken', generateUniqueToken());
  }, []);
  const [opencaseyes, setOpenCaseyes] = React.useState(false);


  const handleClosecaseyes = () => {
    setOpenCaseyes(false);
  };



  const formFields = {
    name: '',
    email: '',
    phone: '',
    contact_us: '',
    casestudyname: '',
    pagename: 'talktous',

    sh_date: '',
    sh_time: '',


  }
  const [loader, setLoader] = React.useState(true);

  const { ip_address } = useSelector((state) => state.userdata);
  const [formValues, setFormValues] = React.useState(formFields);
  const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [otpid, setotpid] = React.useState(null);
  const [pageselect, setPageselect] = React.useState('');
  const [openotp, setOpenotp] = React.useState(false);

  const handleClickOpenotp = () => {
    setOpenotp(true);
  };
  const handleCloseotp = () => {
    setOpenotp(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(formValues);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  }
  const handleClickOpenCaseyes = () => {
    setFormValues(formFields);
    //setOpen(true);
    //    recaptchaRef.current.reset();
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);

  };
  const handleClickOpenGetintouch = (e) => {

    setFormValues(formFields);
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);


    var casedeat = { ...formValues };
    casedeat.casestudyname = '';
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.pagename = 'getintouch';
    casedeat.sh_date = '';
    casedeat.sh_time = '';
    setFormValues(casedeat);
  };
  const handleSubmit = (e) => {

    e.preventDefault();

    setSubmitting(false);

    console.log(validateForm());
    if (validateForm()) {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      let formData = { ...formValues };
      formData.recaptcha = recaptchaRef.current.getValue();

      secureLocalStorage.setItem('selectoption', formData);
      setPageselect(formData.pagename);
      addConnect(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.data.status) {
            setotpid(response.data.lastid);
            //setSuccessMessage(response.data.message);
            setFormValues(formFields);
            recaptchaRef.current.reset();
            setOpenCaseyes(false);
            setOpenotp(true);
            // setTimeout(() => {
            //  // setOpen(false);
            // }, 2000);


          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage('Something went wrong. Please try again later.');
        });
    }
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    //setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const validateForm = () => {
    let isValid = true;
    let errors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && key !== 'recaptcha' && key !== 'phone' && key !== 'sh_date' && key !== 'sh_time' && key !== 'casestudyname') {
        isValid = false;
        errors[key] = 'This field is required';
      } else if (key === 'email' && !isEmail(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      }
      // else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
      //   isValid = false;
      //   errors[key] = 'Invalid phone number';
      // }

    });
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      isValid = false;
      errors.recaptcha = 'Please verify captcha';
    }
    console.log(errors);
    setFormErrors(errors);
    return isValid;
  }

  const [otpIn, setOtpin] = useState({
    otpIn_first: "",
    otpIn_secon: "",
    otpIn_thir: "",
    otpIn_forth: "",
    otpIn_fifth: "",
    otpIn_six: "",
    error_first: 1,
    error_secon: 1,
    error_thir: 1,
    error_forth: 1,
    error_fifth: 1,
    error_six: 1,
    signIn_err: '0',
    signIn_mesage: '',
    status: 'fail',
    id: ''
  });
  React.useEffect(() => {
    if (otpIn.otpIn_first != '' && otpIn.otpIn_secon != ''
      && otpIn.otpIn_thir != '' && otpIn.otpIn_forth != '') {
        handleSubmitotp();
    }
 
 }, [otpIn]);

  const inputEventOtpIn = (event) => {
    console.log(event);
    setOtpin((preValue) => {

      if (event.target.name === 'otpIn_first') {

        return {
          otpIn_first: event.target.value,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: 0,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_secon') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: event.target.value,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: 0,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_thir') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: event.target.value,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: 0,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_forth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: event.target.value,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: 0,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }



      if (event.target.name === 'otpIn_fifth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: event.target.value,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: 0,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_six') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: event.target.value,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: 0,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };
      }
    });
    if (event.target.name === 'otpIn_first') {

      document.getElementById('otpIn_secon').focus();
    }

    if (event.target.name === 'otpIn_secon') {

      document.getElementById('otpIn_thir').focus();
    }
    if (event.target.name === 'otpIn_thir') {


      document.getElementById('otpIn_forth').focus();
    }

    if (event.target.name === 'otpIn_forth') {


      //   document.getElementById('otpIn_fifth').focus();
    }



    //      if(event.target.name==='otpIn_fifth'){


    //           document.getElementById('otpIn_six').focus();
    //  }
    console.log(otpIn);

  }


  const handleSubmitotp = (event) => {

    setErrorMessage('');
    setSuccessMessage('');
    if (otpIn.otpIn_first == '' || otpIn.otpIn_secon == ''
      || otpIn.otpIn_thir == '' || otpIn.otpIn_forth == '') {
      setErrorMessage('Please Enter Valid otp');
      return false;
    }
    var otp = otpIn.otpIn_first + otpIn.otpIn_secon + otpIn.otpIn_thir + otpIn.otpIn_forth + otpIn.otpIn_fifth + otpIn.otpIn_six;

    setSubmitting(true);
    otpUpdate({ id: otpid, otp: otp })
      .then(res => {
        console.log(res.data);

        if (res.data.status == true) {
         // setOpen(true);
          setOpenotp(false);
          setSubmitting(false);
          if(pageselect=='getintouch'){
            navigate('/thankyou/getintouch');
          }else if(pageselect=='talktous'){
            navigate('/thankyou/talktous');
          }
          // setTimeout(() => {
          //   //setOpen(false);
          // }, 3000);






        }
        else {

          setErrorMessage(res.data.message);
          setOtpin((preValue) => {

            return {
              otpIn_first: '',
              otpIn_secon: '',
              otpIn_thir: '',
              otpIn_forth: '',
              otpIn_fifth: '',
              otpIn_six: '',
              error_first: 0,
              error_secon: 0,
              error_thir: 0,
              error_forth: 0,
              error_fifth: 0,
              error_six: 0,

            };
          })
          setSubmitting(false);
        }
      });
  }

  return (
    <>
      <Header />
      <div className='main-sec-homes desk-sec-new'>
        {
          homeData?.homebanner.length &&
          <div class="main-sec-home"  title="Accelerating efficiency in k12 school across New Jersey" style={{ backgroundImage: `url(https://www.appostrophi.com/static/media/7851255567_banner-img.385e2556de50282aab31.png)` }}>

          </div>
        }
        <div className='container'>
          <div className='banner-content'>

            <h1>{homeData.homebanner[0].title}</h1>
            <h2>{homeData.homebanner[0].subtitle}</h2>
            {/* <p className='head-para'>{homeData.homebanner[0].short_des}</p>
            <p> {homeData.homebanner[0].short_desnew}</p> */}

          </div>
        </div>
      </div>


      <div className='main-sec-homes  mob-sec-new'>
        {
          homeData?.homebanner.length &&
          <div class="main-sec-home"  title="Accelerating efficiency in k12 school across New Jersey" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${homeData.homebanner[0].mobile_image}')` }}>

          </div>
        }
        <div className='container'>
          <div className='banner-content'>

            <h1>{homeData.homebanner[0].title}</h1>
            <h2>{homeData.homebanner[0].subtitle}</h2>
            {/* <p className='head-para'>{homeData.homebanner[0].short_des}</p>
            <p> {homeData.homebanner[0].short_desnew}</p> */}

          </div>
        </div>
      </div>




      <div className='service-sec service-seccc'>
        {/* service brief section start */}
        <div className='service-desc-sec service-desc-new'>
          <div className='container'>
            <div className="row ">
              <div className="col-md-12">
                <div className='service-headss'>
                  <p className='head-para'>{homeData.homebanner[0].short_des}</p>
                  <p> {homeData.homebanner[0].short_desnew}</p>
                </div>
              </div>
            </div>
            <div className='row servics-main-secsss'>
              {
                homeData?.homeservies.length &&
                <>
                  {
                    homeData.homeservies.map((item, index) => (
                      <div className={`col-lg-3 col-md-6 col-12 col-3 ${index == 0 && 'no-padding-right'} ${index == (homeData.homeservies.length - 1) && 'no-padding-left'} ${(index > 0 && index != (homeData.homeservies.length - 1)) && 'no-padding'}`}>
                        <div className={`service-desc-inner  ${index == 0 && 'service-one-line'} ${index == 1 && 'service-two-line'} ${(index == 2) && 'service-three-line'} ${(index == 3) && 'service-four-line'} `}>
                          {/* <ScrollAnimation animateIn="fadeIn"> */}
                          <a>
                            <img src={process.env.REACT_APP_IMAGE_URL + item.image} 
                            alt={`${item.id==1 ? 'Gap_Analysis - Icon'  : ''} ${item.id==2 ? 'Efficiency_Audit - Icon'  :''}  ${item.id==3 ? 'Bespoke Programming - Icon'  :''} ${item.id==4 ? 'Informed Decision Taking Icon'  :''}`} 
                            
                            />
                            <img src={process.env.REACT_APP_IMAGE_URL + item.image_hover} className="whitelogo" 
                              alt={`${item.id==1 ? 'Gap_Analysis - White Icon'  :''} ${item.id==2 ? 'Efficiency_Audit - White Icon'  :''}  ${item.id==3 ? 'Bespoke Programming - White Icon'  :'' } ${item.id==4 ? 'Informed Decision Taking White Icon'  :''}`} 
                            
                            
                            />
                            <h3>{item.title}</h3>
                            <p>{item.short_des}</p>

                          </a>
                          {/* </ScrollAnimation> */}
                        </div>

                      </div>
                    ))
                  }
                </>

              }






            </div>
          </div>
        </div>
        {/* service brief section end */}

        {/* service section start */}
        <div className='service'>
          <div className='container'>
            <div className='row'>
              <div className='service-head'>
                <ScrollAnimation animateIn="fadeIn">
                  {
                    homeData?.hometitle &&
                    <h2>{homeData.hometitle.servicetitle}</h2>
                  }
                </ScrollAnimation>
              </div>

              <div className='service-inner-sec'>
                {
                  homeData?.services.length &&
                  <>
                    {
                      homeData.services.map((ser, index) => (
                        // <ScrollAnimation animateIn="fadeIn">
                        <div className={`service-iiner  ${index == 0 && 'service-one'} ${index == 1 && 'service-two'} ${(index == 2) && 'service-three'} ${(index == 3) && 'service-four'} ${(index == 4) && 'service-five'} `}>
                          <img src={process.env.REACT_APP_IMAGE_URL + ser.image}  
                           alt={`${ser.id==1 ? 'Workflow Management & Automation as a Service to K12 Schools in New Jersey - Icon'  :''} ${ser.id==2 ? 'Gap_Analysis as a Service to K12 Schools in New Jersey - Icon'  :''}  ${ser.id==3 ? 'Efficiency_Audit as a Service to K12 Schools in New Jersey - Icon'  :''} ${ser.id==4 ? 'Software Development as a Service to K12 Schools in New Jersey - Icon'  :''} ${ser.id==5 ? 'Reporting at NJDOE as a service for K12 schools in new jerset'  :''}`} 
                          
                          />

                          <h3>{ser.title}</h3>
                        </div>
                        // </ScrollAnimation>



                      ))
                    }
                  </>

                }


              </div>

            </div>
            <div className="row">
              <div className="col-md-12">
                <ScrollAnimation animateIn="fadeIn">
                  <div className='button-sec touch-sec'>
                    <a class="btn btn-sec touch-btn-se" href="javascript:void(0)" onClick={() => handleClickOpenGetintouch()}>Get in Touch</a>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
        {/* service section end */}
      </div>


      {/* pre expended section start */}

      {/* <div className='pre-expended-secs'>
        <div className='pre-expended-sec'></div>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='pre-expended-inner'>

                {
                  homeData?.hometitle &&
                  <p>{homeData.hometitle.projecttitle}</p>
                }
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className='pre-expended-secs-new'>
        <div className='pre-expended-sec-new'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <ScrollAnimation animateIn="fadeIn">
                  <div className='pre-expended-inner-new'>

                    {
                      homeData?.hometitle &&
                      <p>{homeData.hometitle.projecttitle}</p>
                    }
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* pre expended section end */}


      <div className='bg-only'>
        {/* project section start */}
        <div className='home-project-sec'>




          {
            homeData?.projects.length &&
            <>
              {
                homeData.projects.map((pro, index) => (

                  <div className='project-sec-width '>
                    <div className='project-inner-sec'>

                      <img src={projectimg}   
                      alt={`${pro.id==1 ? 'Eform System for k12 Schools in New Jersey'  :''} ${pro.id==2 ? 'Workshop Management system for k12 Scools in New Jersey'  :''}  ${pro.id==4 ? 'Facility Rental Management System for k12 Schools in New Jersey'  :''} ${pro.id==5 ? 'Student Transcript Application for k12 Scools in New Jersey'  :''} `} 
                      />
                      {/* <ScrollAnimation animateIn="fadeIn"> */}
                      <h3>{pro.short_des}</h3>
                      {/* </ScrollAnimation> */}
                      <div className='project-acn-sec-main'>
                        <div className='project-acn-sec'>
                          {(pro.video != '' && pro.video != null) &&

                            <div className='proj-actn-left'>
                              <a onClick={() => handleClickOpenVideo(pro.video, pro.video_image)}><span>Watch Video</span><i class="fa-solid fa-arrow-right-long"></i></a>
                            </div>}

                          <div className='proj-actn-right'>
                            <Link to={`casestudies/${pro.slug}`}><span>View Case Studies</span><i class="fa-solid fa-arrow-right-long"></i></Link>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>



                ))
              }
            </>

          }



        </div>
        <ScrollAnimation animateIn="fadeIn">
          <div className='button-sec'>
            <Link to={`/project`} className='btn btn-sec'>VIEW ALL PROJECTS</Link>
          </div>
        </ScrollAnimation>

        {/* project section end */}

        {/* contact us section start */}
        {
          homeData?.homevision &&
          <div className='vision-sec'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-2'>
                  <img className="vision-img" src={process.env.REACT_APP_IMAGE_URL + homeData.homevision.image} alt={`Vision - Icon`} />
                </div>
                <div className='col-md-10'>
                  <ScrollAnimation animateIn="fadeIn">
                    <div className='vision-inner'>
                      <h2>{homeData.homevision.title}</h2>
                      <h3>{homeData.homevision.short_des}</h3>
                      <p>{homeData.homevision.description}</p>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        }


        {/* contact use section end */}
      </div>


      <div className='unique-sec'>
        {/* partner section start */}

        {
          homeData?.homepartner &&
          <div className='partner-sec'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-6 no-paddings'>
                  <div className='partner-inner' >
                    <ScrollAnimation animateIn="fadeIn">
                      <h2>{homeData.homepartner.title}</h2>
                      <h3>{homeData.homepartner.subtitle}</h3>
                      <p>{homeData.homepartner.short_des}</p>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className='col-md-6 no-paddings'>
                  <div className='talk-inner'>
                    <ScrollAnimation animateIn="fadeIn">
                      <h2>{homeData.homepartner.subtiltle_one}</h2>
                      <a className='callltoactn' onClick={handleClickOpenCaseyes}>
                        <img src={callimg}  alt={`Talk to us - Icon`}/>
                        <span>Talk To Us</span></a>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {/* partner section end */}

        {/* unique section start */}

        {
          homeData?.homeunique &&
          <div className='vision-sec unique-sec'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-2'>
                  <ScrollAnimation animateIn="fadeIn">
                    <img className="vision-img" src={process.env.REACT_APP_IMAGE_URL + homeData.homeunique.image} alt={`Customer Uniqueness Icon`}/>
                  </ScrollAnimation>
                </div>
                <div className='col-md-10'>
                  <ScrollAnimation animateIn="fadeIn">
                    <div className='vision-inner'>
                      <h2>{homeData.homeunique.title}</h2>

                      <p>
                        {homeData.homeunique.short_des}
                      </p>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        }

        {/* unique section end */}
      </div>
      <Footer />


      {/* modal code start here */}
      <BootstrapDialog
        onClose={handleClosevideo}
        aria-labelledby="customized-dialog-title"
        open={openvideo} className="modal-casestudy modal-watch"
      >

        <div className="btn-mdl-sec">
          <IconButton
            aria-label="close"
            onClick={handleClosevideo}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >

            < CancelIcon />

          </IconButton>
        </div>
        <DialogContent dividers>
          <Typography gutterBottom>
            {(videof != null && imgfallvid != '') &&
              <Media style={{ width: "100%" }}>
                <Video autoplay="true" loading="visible" controls preload="true" poster={process.env.REACT_APP_IMAGE_URL + imgfallvid}>
                  <video loading="visible" poster={process.env.REACT_APP_IMAGE_URL + imgfallvid} autoplay="true" src={process.env.REACT_APP_IMAGE_URL + videof} preload="none" data-video="0" controls />
                </Video>
              </Media>

            }
          </Typography>

        </DialogContent>

      </BootstrapDialog>



      <BootstrapDialog
        onClose={handleClosecaseyes}
        aria-labelledby="customized-dialog-title"
        open={opencaseyes} className="modal-casestudy modal-yes happy-modal modal-cases final-modal"
      >

        <div className="btn-mdl-sec">
          <IconButton
            aria-label="close"
            onClick={handleClosecaseyes}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >

            < CancelIcon />

          </IconButton>
        </div>
        <DialogContent dividers>
          <Typography gutterBottom>
            <h2>Happy to help!</h2>
            {/* <p>Let us send you more case studies</p> */}

            <div className="form-sec-main">
              <div className='form-sec form-modal'>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >

                  <TextField id="standard-basic" label="Your Full Name" variant="standard"
                    name="name"
                    className={classNames("form-control", { 'is-invalid': formErrors.name })}
                    onChange={handleInputChange}
                    style={{ backgroundImage: 'none' }}
                    value={formValues.name}

                  />
                  {
                    formErrors.name &&
                    <div className="invalid-feedback">
                      <div className='error-msg'>
                        {formErrors.name}
                      </div>
                    </div>
                  }
                </Box>
              </div>
              <div className='form-sec form-modal'>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >

                  <TextField id="standard-basic" label="Your Email Id" variant="standard"
                    name="email"
                    className={classNames("form-control", { 'is-invalid': formErrors.email })}
                    onChange={handleInputChange}
                    style={{ backgroundImage: 'none' }}
                    value={formValues.email}

                  />
                  {
                    formErrors.email &&
                    <div className="invalid-feedback">
                      <div className='error-msg'>
                        {formErrors.email}
                      </div>
                    </div>
                  }
                </Box>
                <p className='veri-secss'><span>This email will be verified</span></p>

              </div>
              <div className='form-sec form-modal'>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >

                  <TextField id="standard-basic" label="Your Contact Number" variant="standard"

                    name="phone"
                    className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                    onChange={handleInputChange}
                    style={{ backgroundImage: 'none' }}
                    value={formValues.phone}
                  />
                  {
                    formErrors.phone &&
                    <div className="invalid-feedback">
                      <div className='error-msg'>
                        {formErrors.phone}
                      </div>
                    </div>
                  }
                </Box>

                <div class="tooltipnew"> <i class="fa-solid fa-info-circle"></i>
                  <span class="tooltiptext"> In case you need us to contact you</span>
                </div>

              </div>

              <div className='form-sec form-modal form-radio-sec'>

              <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" className="contactng-sec">You are contacting us for</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"

                    name="radio-buttons-group"
                    style={{ backgroundImage: 'none' }}
                  >
                    <FormControlLabel value="you need more case studies"
                      checked={formValues.contact_us == 'you need more case studies'}
                      name="contact_us"
                      onChange={handleInputChange}
                      control={<Radio />} label="You need more case studies"

                    />
                    {/* <FormControlLabel value="You are interested in a product demo"
                      name="contact_us"
                      checked={formValues.contact_us == 'You are interested in a product demo'}
                      onChange={handleInputChange}
                      control={<Radio />} label="You are interested in a product demo" /> */}
                    <FormControlLabel value="you want us to send you our company presentation"
                      name="contact_us"
                      checked={formValues.contact_us == 'you want us to send you our company presentation'}
                      onChange={handleInputChange}
                      control={<Radio />} label="You want us to send you our company presentation" />
                    <FormControlLabel value="you want a representative to call you"
                      checked={formValues.contact_us == 'you want a representative to call you'}
                      name="contact_us"
                      onChange={handleInputChange}
                      control={<Radio />} label="You want a representative to call you" />

                  </RadioGroup>
                </FormControl>





                {
                  formErrors.contact_us &&
                  <div className="invalid-feedbackcontact">
                    <div className='error-msg'>
                      {formErrors.contact_us}
                    </div>
                  </div>
                }

              </div>
              <div className='form-sec form-modal'>

                <p className="case-privacy">Your information is secured with us, please read our <Link to='/privacy-policy' className="case-link-prv">privacy policy</Link> </p>
              </div>
              <div className='form-sec form-modal' >


                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
                />
                {
                  formErrors.recaptcha &&
                  <div className="invalid-feedback" style={{ display: 'block' }}>
                    <div className='error-msg'>
                      {formErrors.recaptcha}
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className='button-sec button-case'>

              <a className='btn btn-sec btn-yes' onClick={handleSubmit}>Submit

                {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>}
              </a>
            </div>
            <div className='validate-sec validate-sec-mode'>
              {
                successMessage &&
                <div className="success-msg-sec">
                  <p className="succ-msg">{successMessage}
                  </p>
                </div>
              }
              {
                errorMessage &&
                <div className="error-msg-sec">
                  <p className="error-msg">{errorMessage}
                  </p>
                </div>
              }
            </div>
          </Typography>

        </DialogContent>

      </BootstrapDialog>


      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} className="modal-casestudy contact-thanku modal-thanku-connet"
      >

        <div className="btn-mdl-sec">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >

            < CancelIcon />

          </IconButton>
        </div>
        <DialogContent dividers>
          <Typography gutterBottom>
            <h2>Thank You !!</h2>
            <p>Your message is successfully delivered to the concerned team, they will do the needful soon</p>


          </Typography>

        </DialogContent>

      </BootstrapDialog>

      <BootstrapDialog
        onClose={handleCloseotp}
        aria-labelledby="customized-dialog-title"
        open={openotp} className="modal-casestudy modal-yes happy-modal otp-modal-new"
      >

        <div className="btn-mdl-sec">
          <IconButton
            aria-label="close"
            onClick={handleCloseotp}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >

            < CancelIcon />

          </IconButton>
        </div>
        <DialogContent dividers className="new-otp">
          <Typography gutterBottom >
            <p className="otp-head">Please check your spam or junk folder, if your authentication code is not visible in your inbox
            </p>

            <div className="form-sec-main otp-main-secss">

              <div className='form-sec form-modal'>
                <div className='otp-form form__group inputs d-flex flex-row justify-content-center mt-2'>
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_first" id="otpIn_first" onChange={inputEventOtpIn} value={otpIn.otpIn_first} />
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_secon" id="otpIn_secon" onChange={inputEventOtpIn} value={otpIn.otpIn_secon} />
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_thir" id="otpIn_thir" onChange={inputEventOtpIn} value={otpIn.otpIn_thir} />
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_forth" id="otpIn_forth" onChange={inputEventOtpIn} value={otpIn.otpIn_forth}  />
                </div>



              </div>
              <div className='button-sec button-case btn-otp'>

                <a className='btn btn-sec btn-yes btn-sub' onClick={handleSubmitotp}>Submit

                  {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>}
                </a>
              </div>
              <div className='validate-sec validate-sec-mode width-otp'>
                {
                  successMessage &&
                  <div className="success-msg-sec">
                    <p className="succ-msg">{successMessage}
                    </p>
                  </div>
                }
                {
                  errorMessage &&
                  <div className="error-msg-sec">
                    <p className="error-msg">{errorMessage}
                    </p>
                  </div>
                }
              </div>
            </div>
          </Typography>

        </DialogContent>

      </BootstrapDialog>

      {/* modal code end here   */}
    </>
  )
}
export default Home;

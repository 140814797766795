import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import AOS from "aos";
import { BrowserRouter, Routes, Route, Navigate,useParams,
  useNavigate, } from "react-router-dom";
import { AppContext } from "./context";
import Home from "./components/home";
import Aboutus from "./components/aboutus";
// import Header from './components/header';
// import Footer from './components/footer';
import {
  getHomePageContent,
  getAboutPageContent,
  getSerCasCarConPageContent,
  checkIdco
} from "./actions/pagecontent.actions";
import { setPageContent } from "./features/pageContentSlice";

import { setFormToken, setIpAddress } from "./features/userdataSlice";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "./utils/ScrollToTop";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { generateUniqueToken } from "./utils/helper";
import axios from "axios";
import Preloader from "./ui-components/Preloader";
import Services from "./components/services";
import Casestudies from "./components/casestudies";
import Project from "./components/project";
import Career from "./components/career";
import Contact from "./components/contact";

import Programming from "./components/programming";
import Gapanalysis from "./components/gapanalysis";
import Efficiencyaudit from "./components/efficiencyaudit";
import Workflow from "./components/workflow";
import Njsmartcollection from "./components/njsmart-njdoe-data-collection";
import Contactnew from "./components/contactnew";
import Privacypolicy from "./components/privacy-policy";
import Termscondition from "./components/terms-condition";
import Thankyou from "./components/thankyou";
import Bookademo from "./components/book-a-demo";
import Zoho from "./components/zoho";
import Bookademonew from "./components/bookademo";
import Casestudypdf from "./components/casestudy-pdf";

function NoMatch() {
  alert("No Match");
  return <Navigate to='/' />
}

function App() {
  const [pageLoaded, setPageLoaded] = useState(false);
  const dispatch = useDispatch();

  const formToken = useSelector((state) => state.userdata.formToken);
  const { id } = useParams();
  React.useEffect(() => {
    AOS.init();
    var pathname = window.location.pathname;

   
    /**
     * Load Contens of all pages
     */
    getHomePageContent()
      .then((res) => {
        if (res.data.status) {
          dispatch(setPageContent({ key: "home", data: res.data.home }));
          getAboutPageContent()
            .then((res) => {
              if (res.data.status) {
                dispatch(
                  setPageContent({ key: "about", data: res.data.about })
                );

                getSerCasCarConPageContent()
                  .then((res) => {
                    if (res.data.status) {
                      dispatch(
                        setPageContent({
                          key: "career",
                          data: res.data.career,
                        })
                      );
                      dispatch(
                        setPageContent({
                          key: "casestudies",
                          data: res.data.casestudies,
                        })
                      );
                      dispatch(
                        setPageContent({
                          key: "contact",
                          data: res.data.contact,
                        })
                      );
                      dispatch(
                        setPageContent({
                          key: "service",
                          data: res.data.service,
                        })
                      );
                      dispatch(
                        setPageContent({
                          key: "project",
                          data: res.data.project,
                        })
                      );
                      dispatch(
                        setPageContent({
                          key: "privacypolicy",
                          data: res.data.privacy,
                        })
                      );
                      dispatch(
                        setPageContent({
                          key: "cancelpolicy",
                          data: res.data.terms,
                        })
                      );
                      setPageLoaded(true);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (!formToken) {
      const token = generateUniqueToken();
      dispatch(setFormToken(token));
    }

    //get user ip
    loadIp();

    // setTimeout(() =>, 1500);
  }, []);

  const loadIp = () => {
    axios
      .get("https://ipinfo.io/json")
      .then((res) => {
        dispatch(setIpAddress(res.data.ip));
      })
      .catch((err) => {
        loadIp();
      });
  };

  var data = [];

  const [storage, setStorage] = React.useState([data]);

  const dispatchStorageEvent = (payload) => {
    setStorage(payload);

    return true;
  };

  const handleAcceptCookie = () => { };

  var baseName = "/";
  return (
    <div className="App">
      {!pageLoaded ? (
        <Preloader />
      ) : (
        <>
          <AppContext.Provider value={{ storage, dispatchStorageEvent }}>
            <BrowserRouter basename={baseName}>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route path="/header" element={<Header />} />
            <Route path="/footer" element={<Footer />} /> */}
                <Route path="/aboutus" element={<Aboutus />} />

                <Route path="/services" element={<Services />} />
                <Route path="/casestudies/:slug" element={<Casestudies />} />
                <Route path="/project" element={<Project />} />

                <Route path="/career" element={<Career />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/:slug" element={<Programming />} />
                <Route path="/gapanalysis" element={<Gapanalysis />} />

                <Route path="/efficiencyaudit" element={<Efficiencyaudit />} />
                <Route
                  path="/njsmart-njdoe-data-collection"
                  element={<Njsmartcollection />}
                />
                <Route path="/workflow" element={<Workflow />} />
                <Route
                  path="/zoho-domain-verification.html"
                  element={<Zoho />}
                />
                <Route path="/contactnew" element={<Contactnew />} />
                <Route path="/privacy-policy" element={<Privacypolicy />} />
                <Route path="/terms-condition" element={<Termscondition />} />

                {/* <Route path="/thankyou" element={<Thankyou />} /> */}
                <Route path="/book-a-demo" element={<Bookademo />} />
                <Route path="/bookademo/:id" element={<Bookademonew />} />

                <Route path="/thankyou/contact" element={<Thankyou />} />
                <Route path="/thankyou/getintouch" element={<Thankyou />} />
                <Route path="/thankyou/talktous" element={<Thankyou />} />
                <Route path="/thankyou/talktoleadership" element={<Thankyou />} />
                <Route path="/thankyou/talktoexperts" element={<Thankyou />} />
                <Route path="/thankyou/morecasestudies" element={<Thankyou />} />
                <Route path="/thankyou/bookademocasestudies" element={<Thankyou />} />
                <Route path="/thankyou/bookademoproject" element={<Thankyou />} />
                <Route path="/casestudy-pdf" element={<Casestudypdf />} />

              </Routes>
            </BrowserRouter>

            <CookieConsent
              location="bottom"
              buttonText="Accept"
              cookieName="smfacookieconsent"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
              onAccept={handleAcceptCookie}
              enableDeclineButton
              declineButtonText="Reject"
              buttonClasses="accptbtn"
              declineButtonClasses="declinbtn" className="cookis-sec"
            >
              We value your privacy. These cookies help us personalize your
              experience.
            </CookieConsent>
          </AppContext.Provider>
        </>
      )}
    </div>
  );
}

export default App;

import React, { useState, useRef, useEffect } from "react";
import Header from './header';
import Footer from './footer';
import { useSelector } from "react-redux";
import {
  Link, useLocation,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate
} from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
const Thankyou = () => {
  const { homeData } = useSelector((state) => state.pageContents);
  const { pathname } = useLocation();
  const [pageselect, setPageselect] = React.useState('');
  const [userData, setuserData] = React.useState(null);
  let navigate = useNavigate();
  useEffect(() => {
    console.log(pathname);
    if (pathname == "/thankyou/contact") {
      setPageselect("contact");
    } else if (pathname == "/thankyou/getintouch") {
      setPageselect("getintouch");
    } else if (pathname == "/thankyou/talktous") {
      setPageselect("talktous");
    } else if (pathname == "/thankyou/talktoleadership") {
      setPageselect("talktoleadership");
    } else if (pathname == "/thankyou/talktoexperts") {
      setPageselect("talktoexperts");
    } else if (pathname == "/thankyou/morecasestudies") {
      setPageselect("morecasestudies");
    } else if (pathname == "/thankyou/bookademocasestudies") {
      setPageselect("bookademocasestudies");
    } else if (pathname == "/thankyou/bookademoproject") {
      setPageselect("bookademoproject");
    }
    var userid = secureLocalStorage.getItem('selectoption');;
    console.log(userid);
    if (userid == null) {


      //navigate(`/`);

    } else {
      setuserData((secureLocalStorage.getItem('selectoption')));
    }
    // setTimeout(() => {
    //   navigate('/');
    // }, 4000);

  }, []);
  return (
    <>

   

      <Header />




      <div className="thankyou-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="thank-you-inner">
                <div>
                  <svg
                    width="115px"
                    height="115px"
                    viewBox="0 0 133 133"
                    version="1.1"
                  >
                    <g
                      id="check-group"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <circle
                        id="filled-circle"
                        fill="#07b481"
                        cx="66.5"
                        cy="66.5"
                        r="54.5"
                      />
                      <circle
                        id="white-circle"
                        fill="#FFFFFF"
                        cx="66.5"
                        cy="66.5"
                        r="55.5"
                      />
                      <circle
                        id="outline"
                        stroke="#07b481"
                        stroke-width="4"
                        cx="66.5"
                        cy="66.5"
                        r="54.5"
                      />
                      <polyline
                        id="check"
                        stroke="#FFFFFF"
                        stroke-width="5.5"
                        points="41 70 56 85 92 49"
                      />
                    </g>
                  </svg>
                </div>
                <h2>Thank You</h2>
                {pageselect == 'contact' && <p>Thank you for contacting us. We will get back to you soon</p>}
                {pageselect == 'morecasestudies' && 
                <>
                 <p className="thankyou-msg">Thank you for requesting our amazing case studies.
                 They’ll be in your inbox within the next 5 minutes.</p>
                
               
                </>
               
                }
                {pageselect == 'bookademocasestudies' && <p>Thank you for requesting a demo. You will hear from us shortly</p>}
                {pageselect == 'bookademoproject' && <p>Thank you for requesting a demo. You will hear from us shortly</p>}


                {(pageselect == 'talktoexperts' || pageselect == 'talktoleadership' || pageselect == 'talktous' || pageselect == 'getintouch') &&
                  <>{userData != null &&

                    <>
                      {userData.contact_us == 'you need more case studies'
                        &&

                        <p className="thank-amaze">Thank you for requesting our amazing case studies. We will be sending you soon</p>
                      }


                      {userData.contact_us == 'you want us to send you our company presentation'
                        &&
                        <p className="thank-presting">Thank you for requesting our company presentation. We will be sending you one shortly</p>
                      }

                      {userData.contact_us == 'you want a representative to call you'
                        &&
                        <p className="thankyou-callback">Thank you for requesting a call back. We will get back to you asap.</p>
                      }
                    </>}

                  </>

                }
                
                <a className={`button-sec case-btn-sec more-sec-main book-new-thanyou ${pageselect == 'morecasestudies' && 'book-caes'}`}>
                                
                                  <Link to="/" class="prev-sec-Case Studies case-pre-new ">
                                    <i class="fa-solid fa-arrow-left-long arrow-one left-arrow"></i><span>Back To Home</span>                                   
                                </Link>

                  </a>
                  {pageselect == 'morecasestudies' && 
                <>
                 <p className="vasestremco">(Remember to check your spam folder if you dont find it in your inbox)</p>
                </>
               
                }

                 
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

    </>
  )
}
export default Thankyou;
